import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GiFactory, GiMineralPearls, GiMetalBar } from "react-icons/gi";
import { BsMinecartLoaded } from "react-icons/bs";
import Layout from "@/components/Layout";
import ServiceCart from "@/components/Carts /ServiceCart";
import useServices from "@/hooks/useServices";
import { CustomRenderer } from "@/utils/CustomRenderer";
const OurBusiness = (props: any) => {
  const { menus } = props.pageContext;
  const { content } = props.data.cms.page;
  return (
    <Layout title="Our Business" pageContext={menus}>
      <div className="container">
        {/* <div className="grid sm:grid-cols-2 grid-cols-1 gap-[1rem] lg:grid-cols-3"> */}
        <CustomRenderer document={content.document} />
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ID($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        content {
          document(hydrateRelationships: true)
        }
      }
      services {
        content {
          document
        }
        featureImage {
          extension
          id
          height
          filesize
          width
          url
        }
        id
      }
    }
  }
`;
export default OurBusiness;
